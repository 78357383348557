import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { get } from 'lodash';
import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { DatePicker } from '@app/components/Shared/Inputs/DatePicker';
import {
  formatHour,
  getTimeHourItems,
  getTimeMinItems,
} from '@app/utils/format';

export interface DateAndTimeProps {
  clearButton?: boolean;
  disabled?: boolean;
  fieldDateName?: string;
  fieldHourName?: string;
  fieldMinuteName?: string;
  hideErrorMessage?: boolean;
  minuteInterval?: number;
}

export function DateAndTime({
  clearButton = false,
  fieldDateName,
  fieldHourName,
  fieldMinuteName,
  hideErrorMessage = false,
  minuteInterval = 5,
  disabled,
}: DateAndTimeProps): ReactElement {
  const hourItems = getTimeHourItems();
  const minItems = getTimeMinItems(minuteInterval);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const handleClickClear = () => {
    if (fieldDateName) {
      setValue(fieldDateName, '');
      clearErrors(fieldDateName);
    }
    if (fieldHourName) {
      setValue(fieldHourName, '');
      clearErrors(fieldHourName);
    }
    if (fieldMinuteName) {
      setValue(fieldMinuteName, '');
      clearErrors(fieldMinuteName);
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={1} alignItems="center">
        {fieldDateName && (
          <Controller
            name={fieldDateName}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                disabled={disabled}
                {...field}
                inputRef={field.ref}
                error={!!error}
                actions={['clear']}
                readOnly
              />
            )}
          />
        )}
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          divider={<Typography variant="body3">：</Typography>}
        >
          {fieldHourName && (
            <Controller
              name={fieldHourName}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  error={!!error}
                  displayEmpty
                  disabled={disabled}
                  renderValue={(value: string) => {
                    if (value) return value;
                    return (
                      <Typography variant="body2" color="secondary">
                        08
                      </Typography>
                    );
                  }}
                  sx={{ width: 80 }}
                >
                  <MenuItem value="">{'未選択'}</MenuItem>
                  {hourItems.map((hour, index) => (
                    <MenuItem key={index} value={hour.toString()}>
                      {formatHour(hour)}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          )}
          {fieldMinuteName && (
            <Controller
              name={fieldMinuteName}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  error={!!error}
                  displayEmpty
                  disabled={disabled}
                  renderValue={(value: string) => {
                    if (value) return value;
                    return (
                      <Typography variant="body2" color="secondary">
                        00
                      </Typography>
                    );
                  }}
                  sx={{ width: 80 }}
                >
                  <MenuItem value="">{'未選択'}</MenuItem>
                  {minItems.map((minutes, index) => (
                    <MenuItem key={index} value={minutes.toString()}>
                      {minutes.toString().padStart(2, '0')}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          )}
        </Stack>
        {clearButton && (
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => handleClickClear()}
            sx={{ height: 32 }}
          >
            クリア
          </Button>
        )}
      </Stack>
      {!hideErrorMessage &&
        Array.from(
          new Set([
            get(errors, `${fieldDateName}.message`, ''),
            get(errors, `${fieldHourName}.message`, ''),
            get(errors, `${fieldMinuteName}.message`, ''),
          ])
        )
          .filter((m) => !!m)
          .map((message, index) => (
            <FormHelperText key={index} error>
              {message as string}
            </FormHelperText>
          ))}
    </Box>
  );
}
