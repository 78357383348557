import { useCallback, useEffect, useState } from 'react';

import {
  deleteProduct,
  getProduct,
  updateProduct,
} from '@app/adapter/catalog-service';
import { Product, ProductStatus, ProductStatusKey } from '@app/types/catalog';
import { Common } from '@app/types/common';
import { getPublicationSince, getPublicationUntil } from '@app/utils/catalog';
import { isError } from '@app/utils/error';

export const useProduct = (id?: string) => {
  const [isLoading, setLoading] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  const [product, setProduct] = useState<Product>();
  const fetchProduct = useCallback(async (id: string) => {
    try {
      setLoading(true);
      const { data } = await getProduct(id, {
        expand: 'images,category,variants,attributes',
      });
      setProduct(data);
      return data;
    } catch (error) {
      if (isError(error)) {
        console.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (id) {
      void fetchProduct(id);
    }
  }, [id, fetchProduct]);

  const updateStatus = useCallback(
    async (status: ProductStatusKey, targetData?: Product) => {
      try {
        const target = targetData || product;
        if (!target) {
          throw new Error('指定データがありません');
        }

        setUpdating(true);
        const { data } = await updateProduct(target.id, {
          customFields: {
            autoStopReason:
              status === ProductStatus.ACTIVE
                ? Common.EMPTY
                : target.customFields?.autoStopReason,
          },
          publication: {
            since: getPublicationSince(status, target.publication.since),
            status,
            until: getPublicationUntil(status, target.publication.until),
          },
        });
        const latest = {
          ...target,
          customFields: {
            ...target.customFields,
            autoStopReason: data.customFields?.autoStopReason,
          },
          publication: {
            ...target.publication,
            since: data.publication.since,
            status: data.publication.status,
            until: data.publication.until,
          },
          updatedAt: data.updatedAt,
        };
        !!product && setProduct(latest);
        return latest;
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
      } finally {
        setUpdating(false);
      }
    },
    [product]
  );

  const removeProduct = useCallback(
    async (productId?: string) => {
      try {
        const targetId = productId || id;
        if (!targetId) {
          throw new Error('指定IDがありません');
        }

        setUpdating(true);
        await deleteProduct(targetId);
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
      } finally {
        setUpdating(false);
      }
    },
    [id]
  );

  return {
    fetchProduct,
    isLoading,
    isUpdating,
    product,
    removeProduct,
    setProduct,
    updateStatus,
  };
};
