import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Paper,
  Stack,
  TextField,
  Typography,
  Radio,
  RadioGroup,
} from '@mui/material';
import { ReactElement, useEffect, useMemo, useState, Fragment } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';

import { TransportFormSection } from '@app/components/Catalog/TransportFormSection';
import { AdditionalButton } from '@app/components/Shared/Button/AdditionalButton';
import { DatePicker } from '@app/components/Shared/Inputs/DatePicker';
import { LocationsWithPostalCode } from '@app/components/Shared/Inputs/LocationsWithPostalCode';
import { PhoneNumber } from '@app/components/Shared/Inputs/PhoneNumber';
import { Map } from '@app/components/Shared/Map';
import { ProductSalesStatus, ProductSalesType } from '@app/types/catalog';
import { Common, DateType } from '@app/types/common';
import { MONTH_SEASON_OPTIONS } from '@app/utils/constants';

const SALES_TYPE_OPTIONS = [
  { label: '未定', value: ProductSalesType.PENDING },
  { label: '先着順', value: ProductSalesType.FCFS },
  { label: '登録抽選', value: ProductSalesType.LOTTERY },
];
const DATE_TYPE_OPTIONS = [
  { label: '年月', value: DateType.MONTH },
  { label: '年月日', value: DateType.DAY },
];

export function BasicInfoFormSection(): ReactElement {
  const { clearErrors, control, setValue, trigger } = useFormContext();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'transportSub',
  });
  const salesStatus = useWatch({ control, name: 'salesStatus' });
  const salesType = useWatch({ control, name: 'salesType' });
  const salesDateType = useWatch({ control, name: 'salesDateType' });
  const lotteryDateType = useWatch({ control, name: 'lotteryDateType' });
  const addressLine1 = useWatch({ control, name: 'addressLine1' });
  const addressLine2 = useWatch({ control, name: 'addressLine2' });
  const addressLine3 = useWatch({ control, name: 'addressLine3' });
  const addressLine4 = useWatch({ control, name: 'addressLine4' });
  const geoCode = useWatch({ control, name: 'geoCode' });
  const salesHouseCount = useWatch({ control, name: 'salesHouseCount' });
  const transportationFlag = useWatch({ control, name: 'transportationFlag' });

  const [markAddress, setMarkAddress] = useState('');
  const handleMarkToMap = () => {
    setMarkAddress(
      [addressLine1, addressLine2, addressLine3, addressLine4].join('')
    );
  };

  // 販売開始日の表示ラベル
  const salesDateLabel = useMemo(() => {
    switch (salesType) {
      case ProductSalesType.FCFS:
        return '先着申込受付開始日';
      case ProductSalesType.LOTTERY:
        return '登録受付日';
    }
    return '販売開始日';
  }, [salesType]);

  // 抽選日入力の無効化判定
  const isDisabledLotteryDate = useMemo(() => {
    return (
      salesType === ProductSalesType.PENDING ||
      salesType === ProductSalesType.FCFS
    );
  }, [salesType]);
  useEffect(() => {
    setValue('salesEndDate', '');
    setValue('salesDateSeason', '');
    if (isDisabledLotteryDate) {
      setValue('lotteryDate', '');
      setValue('lotteryDateSeason', '');
      clearErrors('lotteryDate');
    } else if (salesType === ProductSalesType.LOTTERY) {
      void trigger('lotteryDate');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesType]);

  // 販売戸数を監視
  useEffect(() => {
    const value = Number(salesHouseCount);
    if (value < 10 || value > 1) {
      clearErrors('addressLine3');
    }
    if (value >= 10) {
      setValue('addressLine3HiddenFlag', true);
    }
  }, [salesHouseCount, clearErrors, setValue]);

  // 交通利便を監視
  useEffect(() => {
    if (transportationFlag) {
      setValue('transportMainLine', '');
      setValue('transportMainMean', Common.EMPTY);
      setValue('transportMainNearest', '');
      setValue('transportMainNote', '');
      setValue('transportMainTimeMax', '');
      setValue('transportMainTimeMin', '');
      setValue('transportMainSeparateTimeMax.0', '');
      setValue('transportMainSeparateTimeMin.0', '');
      setValue('transportMainSeparateTimeMax.1', '');
      setValue('transportMainSeparateTimeMin.1', '');
      clearErrors([
        'transportMainLine',
        'transportMainMean',
        'transportMainNearest',
        'transportMainNote',
        'transportMainTimeMax',
        'transportMainTimeMin',
        'transportMainSeparateTimeMax.0',
        'transportMainSeparateTimeMin.0',
        'transportMainSeparateTimeMax.1',
        'transportMainSeparateTimeMin.1',
      ]);
      replace([]);
    }
  }, [transportationFlag, clearErrors, replace, setValue]);

  return (
    <Stack spacing={3}>
      <Typography variant="h5">基本情報</Typography>
      <Typography
        fontWeight={700}
        className={`${
          salesStatus === ProductSalesStatus.SCHEDULED_SALE
            ? 'required-label'
            : 'condition-required-label'
        }`}
      >
        販売スケジュール
      </Typography>
      <FormControl fullWidth>
        <FormLabel focused={false}>販売方式</FormLabel>
        <Controller
          name="salesType"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <RadioGroup {...field} row>
                {SALES_TYPE_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>
      <Stack>
        <FormLabel focused={false}>{salesDateLabel}</FormLabel>
        <Controller
          name="salesDateType"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <RadioGroup
                {...field}
                row
                onChange={(e) => {
                  setValue('salesStartDate', '');
                  setValue('salesEndDate', '');
                  setValue('salesDateSeason', '');
                  field.onChange(e.target.value);
                }}
              >
                {DATE_TYPE_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </>
          )}
        />
        <Stack direction="row" spacing={1}>
          <Controller
            name="salesStartDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div>
                {salesDateType === DateType.MONTH ? (
                  <DatePicker
                    {...field}
                    inputRef={field.ref}
                    error={!!error}
                    placeholder="2023/05"
                    datePicker={{
                      inputFormat: 'yyyy年M月',
                      openTo: 'month',
                      value: field.value,
                      views: ['year', 'month'],
                    }}
                    actions={['clear']}
                    readOnly
                  />
                ) : (
                  <DatePicker
                    {...field}
                    inputRef={field.ref}
                    error={!!error}
                    actions={['clear']}
                    readOnly
                  />
                )}
                {error && (
                  <FormHelperText error>
                    {error.message as string}
                  </FormHelperText>
                )}
              </div>
            )}
          />
          {salesDateType === DateType.MONTH ? (
            <Controller
              name="salesDateSeason"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <RadioGroup {...field} row sx={{ ml: 1, mt: 0.5 }}>
                    {MONTH_SEASON_OPTIONS.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.value}
                        control={<Radio />}
                        label={
                          <Typography variant="body2">
                            {option.label}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                  {error && (
                    <FormHelperText error>
                      {error.message as string}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
          ) : (
            salesType === ProductSalesType.LOTTERY && (
              <>
                <Typography pt={1.25}>〜</Typography>
                <Controller
                  name="salesEndDate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <div>
                      {salesDateType === DateType.MONTH ? (
                        <DatePicker
                          {...field}
                          inputRef={field.ref}
                          error={!!error}
                          placeholder="2023/05"
                          datePicker={{
                            inputFormat: 'yyyy年M月',
                            openTo: 'month',
                            value: field.value,
                            views: ['year', 'month'],
                          }}
                          actions={['clear']}
                          readOnly
                        />
                      ) : (
                        <DatePicker
                          {...field}
                          inputRef={field.ref}
                          error={!!error}
                          actions={['clear']}
                          readOnly
                        />
                      )}
                      {error && (
                        <FormHelperText error>
                          {error.message as string}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                />
              </>
            )
          )}
        </Stack>
      </Stack>
      <Stack>
        <FormLabel focused={false}>抽選日</FormLabel>
        <Controller
          name="lotteryDateType"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <RadioGroup
                {...field}
                row
                onChange={(e) => {
                  setValue('lotteryDate', '');
                  setValue('lotteryDateSeason', '');
                  field.onChange(e.target.value);
                }}
              >
                {DATE_TYPE_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio disabled={isDisabledLotteryDate} />}
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </>
          )}
        />
        <Stack direction="row" spacing={2}>
          <Controller
            name="lotteryDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div>
                {lotteryDateType === DateType.MONTH ? (
                  <DatePicker
                    {...field}
                    inputRef={field.ref}
                    error={!!error}
                    disabled={isDisabledLotteryDate}
                    placeholder="2023/05"
                    datePicker={{
                      inputFormat: 'yyyy年M月',
                      openTo: 'month',
                      value: field.value,
                      views: ['year', 'month'],
                    }}
                    actions={['clear']}
                    readOnly
                  />
                ) : (
                  <DatePicker
                    {...field}
                    inputRef={field.ref}
                    error={!!error}
                    disabled={isDisabledLotteryDate}
                    actions={['clear']}
                    readOnly
                  />
                )}
                {error && (
                  <FormHelperText error>
                    {error.message as string}
                  </FormHelperText>
                )}
              </div>
            )}
          />
          {lotteryDateType === DateType.MONTH && (
            <Controller
              name="lotteryDateSeason"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <RadioGroup {...field} row sx={{ mt: 0.5 }}>
                    {MONTH_SEASON_OPTIONS.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.value}
                        control={<Radio disabled={isDisabledLotteryDate} />}
                        label={
                          <Typography variant="body2">
                            {option.label}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                  {error && (
                    <FormHelperText error>
                      {error.message as string}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
          )}
        </Stack>
      </Stack>
      <FormControl fullWidth>
        <FormLabel focused={false}>販売スケジュールコメント</FormLabel>
        <Controller
          name="salesScheduleComment"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
      </FormControl>
      <Typography fontWeight={700}>問い合わせ</Typography>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          問い合わせ先名
        </FormLabel>
        <Controller
          name="contactAddress"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>例）○○販売センター</FormHelperText>
      </FormControl>
      <Stack>
        <FormLabel focused={false} className="required-label">
          問い合わせ先電話番号
        </FormLabel>
        <PhoneNumber
          field1stName="contactPhoneNumber.0"
          field2ndName="contactPhoneNumber.1"
          field3rdName="contactPhoneNumber.2"
        />
      </Stack>
      <FormControl fullWidth>
        <FormLabel focused={false}>問い合わせ先補足</FormLabel>
        <Controller
          name="contactAdditional"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>
          例）営業時間10:00AM ~ 5:00PM / 定休日：火曜日
        </FormHelperText>
      </FormControl>
      <Typography fontWeight="700">所在地</Typography>
      <Stack>
        <FormLabel focused={false} className="required-label">
          物件所在地
        </FormLabel>
        <LocationsWithPostalCode />
      </Stack>
      <Stack>
        <FormLabel focused={false} className="condition-required-label">
          住所末尾
        </FormLabel>
        <Controller
          name="addressLine3"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              placeholder="町名・番地"
            />
          )}
        />
        <Controller
          name="addressLine3HiddenFlag"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <FormControlLabel
                {...field}
                checked={field.value}
                label={<Typography variant="body2">住所末尾を表示</Typography>}
                control={<Checkbox size="small" />}
                onChange={(e) =>
                  field.onChange(salesHouseCount >= 10 ? true : e)
                }
                sx={{ ml: 0, width: 'fit-content' }}
              />
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </div>
          )}
        />
        <FormHelperText>
          ※販売戸数１０戸以上及び１戸の場合は入力必須。
        </FormHelperText>
      </Stack>
      <FormControl fullWidth>
        <FormLabel focused={false}>号棟</FormLabel>
        <Controller
          name="addressLine4"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              placeholder="建物名・部屋番号"
            />
          )}
        />
        <FormHelperText>※ 表示されません</FormHelperText>
      </FormControl>
      <div>
        <Stack direction="row" spacing={1}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleMarkToMap}
          >
            地図にピンを立てる
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            size="small"
            disabled={!geoCode}
            onClick={() => {
              setValue('geoCode', undefined);
              setMarkAddress('');
            }}
          >
            ピンを削除
          </Button>
        </Stack>
        <Paper variant="outlined" sx={{ mt: 2, p: 2 }}>
          <Map
            address={markAddress}
            position={geoCode}
            onChangePosition={(position) => setValue('geoCode', position)}
          />
        </Paper>
      </div>
      <Typography fontWeight={700}>交通</Typography>
      <FormControl fullWidth>
        <FormLabel focused={false}>主要交通</FormLabel>
        <Controller
          name="transportationFlag"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControlLabel
              {...field}
              checked={field.value}
              label={<Typography variant="body2">交通利便なし</Typography>}
              control={<Checkbox />}
              sx={{ ml: 0, width: 'fit-content' }}
            />
          )}
        />
      </FormControl>
      <TransportFormSection
        fieldName={{
          line: 'transportMainLine',
          mean: 'transportMainMean',
          nearest: 'transportMainNearest',
          note: 'transportMainNote',
          separateTimeMax: 'transportMainSeparateTimeMax',
          separateTimeMin: 'transportMainSeparateTimeMin',
          timeMax: 'transportMainTimeMax',
          timeMin: 'transportMainTimeMin',
        }}
        disabled={transportationFlag}
      />
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Typography variant="body3">交通{index + 1}</Typography>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => remove(index)}
              sx={{ height: 32 }}
            >
              <Typography variant="body3">クリア</Typography>
            </Button>
          </Stack>
          <TransportFormSection
            fieldName={{
              line: `transportSub.${index}.line`,
              mean: `transportSub.${index}.mean`,
              nearest: `transportSub.${index}.nearest`,
              note: `transportSub.${index}.note`,
              separateTimeMax: `transportSub.${index}.separateTimeMax`,
              separateTimeMin: `transportSub.${index}.separateTimeMin`,
              timeMax: `transportSub.${index}.timeMax`,
              timeMin: `transportSub.${index}.timeMin`,
            }}
            disabled={transportationFlag}
          />
        </Fragment>
      ))}
      <AdditionalButton
        label="交通の追加"
        disabled={fields.length >= 2 || transportationFlag}
        fullWidth
        onClick={() =>
          append({
            line: '',
            mean: Common.EMPTY,
            nearest: '',
            note: '',
            timeMax: '',
            timeMin: '',
          })
        }
      />
    </Stack>
  );
}
