import {
  format as dateFnsFormat,
  isToday,
  isYesterday,
  isThisYear,
  parseISO,
} from 'date-fns';
import dateFnsJa from 'date-fns/locale/ja';

const localeJP = 'ja-JP';
const currencyJP = 'JPY';
const defaultLocale = localeJP;
const defaultCurrency = currencyJP;

export function localizedPrice(price: number) {
  return new Intl.NumberFormat(defaultLocale, {
    currency: defaultCurrency,
    style: 'currency',
  }).format(price);
}

export function formatPrice(price?: number) {
  if (price === undefined) return 'お問い合わせ後の価格';
  return localizedPrice(price);
}

export function restoreSanitizedString(str: string) {
  let tempStr = str.replace(/\\0/g, '\0');
  tempStr = tempStr.replace(/\\n/g, '\n');
  tempStr = tempStr.replace(/\\r/g, '\r');
  return tempStr;
}

export function jsonParse(jsonStr: string) {
  return JSON.parse(jsonStr.replace(/\\+/g, ''));
}

/**
 * 日付を"YYYY年MM月DD日(曜)"形式にフォーマットする。
 * @param date "YYYY/MM/DD"形式など
 * @returns "YYYY年MM月DD日(曜)"形式 ※変換できないときは空白
 */
export function formatDate(date?: string | Date, isWeek = true) {
  if (!date) {
    return '';
  }
  const d = typeof date === 'string' ? new Date(date) : date;
  if (isNaN(d.getDate())) {
    return '';
  }
  return dateFnsFormat(d, isWeek ? 'yyyy年MM月dd日(eee)' : 'yyyy年MM月dd日', {
    locale: dateFnsJa,
  });
}

export function dateConvertToFormat(
  date?: Date | string,
  format = 'yyyy/MM/dd',
  empty = ''
) {
  if (!date) return empty;
  const d = typeof date === 'string' ? new Date(date) : date;
  if (isNaN(d.getDate())) return empty;
  return dateFnsFormat(d, format, { locale: dateFnsJa });
}

export function dateFormatJp(
  date?: string | Date,
  hideWeek = true,
  empty = ''
) {
  if (!date) return empty;
  const d = typeof date === 'string' ? new Date(date) : date;
  if (isNaN(d.getDate())) return empty;
  return dateFnsFormat(d, `yyyy年MM月dd日${hideWeek ? '' : '(eee)'}`, {
    locale: dateFnsJa,
  });
}

/**
 * UTCのタイムスタンプを日本時間のフォーマットに変換する
 */
export function timestampFormatJp(
  date?: number | string,
  hideWeek = true,
  empty = ''
): string {
  if (!date) return empty;
  return dateFormatJp(convertUtcToJp(date), hideWeek, empty);
}

/**
 * UTCを日本時間に変換する
 */
export function convertUtcToJp(date?: number | string | Date): Date {
  if (!date) return new Date();
  const d = (
    toString.call(date).slice(8, -1) === 'Date' ? date : new Date(date)
  ) as Date;
  // 日本時間 (UTC+9) に変換
  const offset = 9 * 60 * 60 * 1000; // 9時間をミリ秒に変換
  // UTC time
  const utcTime = d.getTime() + d.getTimezoneOffset() * 60 * 1000; // convert to UTC time
  // add Japan offset
  return new Date(utcTime + offset);
}

/**
 * 曜日を"月曜・火曜・水曜"形式にフォーマットする。
 * @param week 曜日の数値配列
 * @param separate 曜日の区切り文字
 * @returns "月曜・火曜・水曜"形式
 */
export function formatWeek(week?: number[], separate = '・') {
  if (!week?.length) {
    return '';
  }
  return week
    ?.map(
      (w) =>
        `${dateFnsJa.localize?.day(w, {
          width: 'short',
        })}曜`
    )
    .join(separate);
}

/**
 * 時間を"hh"形式にフォーマットする。(日付が変わる場合は"翌hh"、"翌々hh"などとする)
 * @param time "hh"形式など ※日を跨ぐ時間（"25"等）でもOK
 * @returns "hh"形式 ※変換できないときは空白
 */
export function formatHour(hourTime?: string | number) {
  if (!hourTime && hourTime !== 0) {
    return '';
  }
  const h = Number(hourTime);
  if (isNaN(h)) {
    return '';
  }
  const dayCount = Math.floor(h / 24);
  const hour = h % 24;
  return `${dayCount > 0 ? '翌' : ''}${
    dayCount > 0 ? '々'.repeat(dayCount - 1) : ''
  }${hour.toString().padStart(2, '0')}`;
}

/**
 * 時間を"hh:mm"形式にフォーマットする。(日付が変わる場合は"翌hh:mm"、"翌々hh:mm"などとする)
 * @param time "hh:mm"形式など ※日を跨ぐ時間（"25:00"等）でもOK
 * @returns "hh:mm"形式 ※変換できないときは空白
 */
export function formatTime(time?: string) {
  if (!time) {
    return '';
  }
  const t = time.split(':');
  const h = Number(t[0]);
  const m = Number(t[1]);
  if (isNaN(h) || isNaN(m)) {
    return '';
  }
  return `${formatHour(h)}:${m.toString().padStart(2, '0')}`;
}

/**
 * 指定の日付を日本語フォーマットに変換して返す
 * @param date Date|string
 * @returns string
 */
export function formatRelativeDate(date: Date | string) {
  const dateObj = convertToDate(date);
  if (isToday(dateObj)) {
    return '今日';
  } else if (isYesterday(dateObj)) {
    return '昨日';
  } else if (isThisYear(dateObj)) {
    return dateFnsFormat(dateObj, 'M月d日(E)', { locale: dateFnsJa });
  }
  return dateFnsFormat(dateObj, 'yyyy年M月d日(E)', { locale: dateFnsJa });
}

function convertToDate(date: Date | string) {
  return typeof date === 'string' ? parseISO(date) : date;
}

/**
 * 時間(時)の選択肢を取得する
 */
export function getTimeHourItems(): string[] {
  return Array.from({ length: 24 }, (h, i) => i.toString().padStart(2, '0'));
}

/**
 * 時間(分)の選択肢を取得する
 */
export function getTimeMinItems(interval = 5): string[] {
  return Array.from({ length: 60 / interval }, (h, i) =>
    (i * interval).toString().padStart(2, '0')
  );
}

/**
 * 文字列のフォーマットが日付であるかを判定する
 */
export function isFormatDay(value?: string): boolean {
  return value ? /^\d{4}\/\d{2}\/\d{2}/.test(value) : false;
}
