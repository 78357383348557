import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { addYears, endOfDay } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { getProducts, updateProducts } from '@app/adapter/catalog-service';
import { ProductCard } from '@app/components/Catalog/ProductCard';
import { SearchCondition } from '@app/components/Catalog/Property/SearchCondition';
import { Alert } from '@app/components/Shared/Alert';
import { ScrollThreshold } from '@app/components/Shared/ScrollThreshold';
import { organizationSelector } from '@app/domain/organization';
import { useProduct } from '@app/hooks/useProduct';
import { useProductPropertyForm } from '@app/hooks/useProductPropertyForm';
import { useRoleChecker } from '@app/hooks/useRoleChecker';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import {
  PropertySearchForm,
  PropertySearchFormData,
  PropertySearchFormDefaultValues,
} from '@app/schemas/catalog';
import {
  Product,
  ProductCategory,
  ProductStatus,
  ProductStatusKey,
  SearchConditionAlert,
} from '@app/types/catalog';
import {
  checkExpiration7DaysAgo,
  checkExpiration1DayAgo,
  checkExpirationAfter,
  checkUpdated4DaysLater,
  checkUpdated7DaysLater,
  checkUpdatedAutoStop,
  getPublicationUntil,
} from '@app/utils/catalog';
import { isError } from '@app/utils/error';

export function PropertyIndex() {
  const navigate = useNavigate();
  const organizationState = useRecoilValue(organizationSelector);
  const { canWriteProductProperty, checkReadProduct } = useRoleChecker();
  const setSnackbar = useSetSnackbar();
  const { updateStatus } = useProduct();
  const { setFormValues, triggerFormAll, checkPublishToActive } =
    useProductPropertyForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const searchForm = useForm<PropertySearchFormData>(PropertySearchForm);

  const [rows, setRows] = useState<Product[]>([]);
  const [rowTotal, setRowTotal] = useState<number>(0);
  const [nextLink, setNextLink] = useState<string>('');
  const fetchProducts = async (next?: string) => {
    if (!organizationState) return;
    try {
      setIsLoading(true);
      const values = searchForm.getValues();
      const { data } = await getProducts(organizationState.id, {
        expand: 'images',
        filter: {
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          addressLine3: values.addressLine3,
          addressLine4: values.addressLine4,
          alert: values.alert,
          category: ProductCategory.PROPERTY,
          id: values.id,
          isSalesStatusByStop: values.isSalesStatusByStop,
          name: values.name,
          postalCode: values.postalCode,
          propertyCategories: values.categories,
          publicationEnd: values.publicationEnd,
          transactionExpirationEnd: values.transactionExpirationEnd,
        },
        nextLink: next,
        page: 0,
        pageSize: 15,
      });
      setRows(next ? [...rows, ...data.value] : data.value);
      setRowTotal(data.total);
      setNextLink(data['@nextLink'] || '');
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitSearch = () => {
    setNextLink('');
    void fetchProducts();
  };

  const setRowsItem = (product: Product) => {
    setRows(rows.map((r) => (r.id === product.id ? product : r)));
  };

  const handleSubmitProductsByAlertError = () => {
    searchForm.reset({
      ...PropertySearchFormDefaultValues,
      alert: SearchConditionAlert.ERROR,
    });
    handleSubmitSearch();
  };

  const [errorProductTotal, setErrorProductTotal] = useState(0);
  const [update4DaysErrorTotal, setUpdate4DaysTotal] = useState(0);
  const [update7DaysErrorTotal, setUpdate7DaysTotal] = useState(0);
  const [updateAutoStopErrorTotal, setUpdateAutoStopTotal] = useState(0);
  const [expiration7DaysErrorTotal, setExpiration7DaysErrorTotal] = useState(0);
  const [expirationTodayErrorTotal, setExpirationTodayErrorTotal] = useState(0);
  const [expirationAfterErrorTotal, setExpirationAfterErrorTotal] = useState(0);

  const isErrorAlert: boolean = useMemo(() => {
    return !!(
      updateAutoStopErrorTotal ||
      update7DaysErrorTotal ||
      update4DaysErrorTotal ||
      expiration7DaysErrorTotal ||
      expirationTodayErrorTotal ||
      expirationAfterErrorTotal
    );
  }, [
    updateAutoStopErrorTotal,
    update7DaysErrorTotal,
    update4DaysErrorTotal,
    expiration7DaysErrorTotal,
    expirationTodayErrorTotal,
    expirationAfterErrorTotal,
  ]);

  const fetchErrorProducts = async (payload?: {
    expirationErrorAlert?: boolean;
    updatedErrorAlert?: boolean;
  }) => {
    if (!organizationState) return;
    try {
      const { data } = await getProducts(organizationState.id, {
        filter: {
          ...payload,
          category: ProductCategory.PROPERTY,
        },
      });
      return data.value;
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
      }
      return [];
    }
  };

  const fetchAllErrorProduct = async () => {
    // 最終更新日エラーアラート取得
    const updateErrors =
      (await fetchErrorProducts({
        updatedErrorAlert: true,
      })) || [];
    // 最終更新日4日経過
    setUpdate4DaysTotal(
      updateErrors.filter(
        (p) => checkUpdated4DaysLater(p) && !checkUpdated7DaysLater(p)
      ).length
    );
    // 最終更新日7日経過
    setUpdate7DaysTotal(
      updateErrors.filter((p) => checkUpdated7DaysLater(p)).length
    );
    // 最終更新日による掲載停止
    setUpdateAutoStopTotal(
      updateErrors.filter((p) => checkUpdatedAutoStop(p)).length
    );
    setErrorProductTotal(updateErrors.length || 0);

    // 取引有効期限エラーアラート取得
    const expirationErrors =
      (await fetchErrorProducts({
        expirationErrorAlert: true,
      })) || [];
    // 取引有効期限7日前
    setExpiration7DaysErrorTotal(
      expirationErrors.filter(
        (p) => checkExpiration7DaysAgo(p) && !checkExpiration1DayAgo(p)
      ).length
    );
    // 取引有効期限1日前
    setExpirationTodayErrorTotal(
      expirationErrors.filter((p) => checkExpiration1DayAgo(p)).length
    );
    // 取引有効期限切れ
    setExpirationAfterErrorTotal(
      expirationErrors.filter((p) => checkExpirationAfter(p)).length
    );
  };

  const updateProductsByUpdatedAt = async () => {
    if (!organizationState) return;
    try {
      setIsUpdating(true);
      // 更新対象の物件を取得
      const {
        data: { value: targets },
      } = await getProducts(organizationState.id, {
        filter: {
          category: ProductCategory.PROPERTY,
          statuses: [ProductStatus.ACTIVE],
        },
        pageSize: 100,
      });

      if (targets.length) {
        // 更新日のみを一括更新する
        await updateProducts(organizationState.id, [
          {
            data: {
              publication: {
                until: getPublicationUntil(ProductStatus.ACTIVE, undefined),
              },
            },
            ids: targets.map((product) => product.id),
          },
        ]);
      }
      setSnackbar(true, '更新しました', 'success');

      // 更新後の物件情報で上書きする
      try {
        void fetchAllErrorProduct();

        const {
          data: { value: newData },
        } = await getProducts(organizationState.id, {
          filter: {
            id: targets.map((product) => product.id),
          },
          pageSize: 100,
        });
        if (newData.length) {
          setRows([
            ...rows.map((row) => {
              const updatedAt = newData.find((d) => d.id === row.id)?.updatedAt;
              return {
                ...row,
                updatedAt: updatedAt || row.updatedAt,
              };
            }),
          ]);
        }
      } catch (e) {
        if (isError(e)) {
          console.error(e.message);
        }
        setIsUpdating(false);
        // 表示上書き失敗で強制的に再取得させる
        void fetchProducts();
        return;
      }
    } catch (e) {
      setSnackbar(true, '更新に失敗しました', 'error');
      if (isError(e)) {
        console.error(e.message);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    void fetchProducts();
    void fetchAllErrorProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateStatus = async (
    status: ProductStatusKey,
    product: Product
  ) => {
    try {
      const productUpdatingPublicationPeriod = {
        ...product,
        publication: {
          ...product.publication,
          until: addYears(endOfDay(new Date()), 10).toISOString(),
        },
      }; // 掲載期限を10年後に設定したproduct
      if (status === ProductStatus.ACTIVE) {
        setFormValues(productUpdatingPublicationPeriod);

        try {
          checkPublishToActive();
        } catch (e) {
          if (isError(e)) {
            setSnackbar(true, e.message, 'error');
          }
          return;
        }

        const isValid = await triggerFormAll();
        if (!isValid) {
          setSnackbar(true, '掲載に必要な入力が満たされてません', 'error');
          return;
        }
      }
      const latest = await updateStatus(
        status,
        productUpdatingPublicationPeriod
      );
      setSnackbar(true, '更新しました', 'success');
      latest && setRowsItem(latest);
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
      }
      setSnackbar(true, '更新に失敗しました', 'error');
    }
  };

  if (!checkReadProduct(ProductCategory.PROPERTY)) {
    return <></>;
  }

  return (
    <>
      <Stack spacing={4}>
        {!!errorProductTotal && (
          <Stack spacing={2.5}>
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Typography variant="h5">エラー一覧</Typography>
              <Typography color="secondary">
                以下の項目をご確認ください。入力推奨・入力注意項目です。
              </Typography>
            </Stack>
            <Alert
              color="error"
              value={`最終更新日から1週間経過したため、まもなく掲載が終了する掲載物が${errorProductTotal}件あります。`}
              action={
                <LoadingButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  loading={isLoading}
                  onClick={handleSubmitProductsByAlertError}
                  sx={{ height: 32 }}
                >
                  該当条件の検索結果を見る
                </LoadingButton>
              }
            />
          </Stack>
        )}
        <Stack spacing={2.5}>
          <Typography variant="h5">絞り込み</Typography>
          <FormProvider {...searchForm}>
            <form>
              <SearchCondition
                isLoading={isLoading}
                onSubmit={handleSubmitSearch}
              />
            </form>
          </FormProvider>
        </Stack>
        <Stack spacing={2.5}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Typography variant="h5">検索結果</Typography>
            <Typography color="secondary">{rowTotal}件</Typography>
            <Box flexGrow={1} />
            {canWriteProductProperty && (
              <LoadingButton
                variant="outlined"
                color="secondary"
                size="small"
                loading={isUpdating}
                startIcon={<AutorenewRoundedIcon />}
                onClick={updateProductsByUpdatedAt}
                sx={{ height: 32 }}
              >
                一括で更新する
              </LoadingButton>
            )}
          </Stack>
          {isErrorAlert && (
            <Stack spacing={1}>
              {!!updateAutoStopErrorTotal && (
                <Alert
                  color="error"
                  value={`掲載更新日から8日が経過したため、掲載が終了した掲載物が${updateAutoStopErrorTotal}件あります。`}
                />
              )}
              {!!update7DaysErrorTotal && (
                <Alert
                  color="error"
                  value={`掲載更新日から7日が経過したため、まもなく掲載が終了する掲載物が${update7DaysErrorTotal}件あります。`}
                />
              )}
              {!!update4DaysErrorTotal && (
                <Alert
                  color="error"
                  value={`掲載更新日から4日が経過したため、まもなく掲載が終了する掲載物が${update4DaysErrorTotal}件あります。`}
                />
              )}
              {!!expiration7DaysErrorTotal && (
                <Alert
                  color="error"
                  value={`取引有効期限切れ7日前となり、まもなく掲載が終了する掲載物が${expiration7DaysErrorTotal}件あります。`}
                />
              )}
              {!!expirationTodayErrorTotal && (
                <Alert
                  color="error"
                  value={`取引有効期限切れ1日前となり、まもなく掲載が終了する掲載物が${expirationTodayErrorTotal}件あります。`}
                />
              )}
              {!!expirationAfterErrorTotal && (
                <Alert
                  color="error"
                  value={`取引有効期限切れとなり、掲載が終了した掲載物が${expirationAfterErrorTotal}件あります。`}
                />
              )}
            </Stack>
          )}
          <div>
            {!!rows.length && (
              <Grid container spacing={2}>
                {rows.map((row, index) => (
                  <Grid key={index} item xs={4}>
                    <ProductCard
                      product={row}
                      readOnly={!canWriteProductProperty}
                      onChange={setRowsItem}
                      onChangeStatus={handleUpdateStatus}
                      onClick={(data) =>
                        navigate(`/properties/products/${data.id}/edit`)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
        </Stack>
        <ScrollThreshold
          disabled={!nextLink}
          thresholdReached={() => {
            nextLink && void fetchProducts(nextLink);
          }}
        />
      </Stack>
    </>
  );
}
