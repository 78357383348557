import { zodResolver } from '@hookform/resolvers/zod';
import { format, startOfDay, isBefore, subDays } from 'date-fns';
import { z } from 'zod';

import {
  optionalInt,
  optionalIntBetween,
  optionalIntLength,
  optionalLengthBetween,
  required,
  requiredInt,
  requiredIntBetween,
  requiredLengthBetween,
} from '@app/schemas/base';
import {
  ProductAutoStopReasonKey,
  ProductBuildingStatusKey,
  ProductCompletionDateType,
  ProductCompletionDateTypeKey,
  ProductDeliveryDateType,
  ProductDeliveryDateTypeKey,
  ProductEventDateTypeKey,
  ProductEventTitleKey,
  ProductRangeType,
  ProductRangeTypeKey,
  ProductAreaTypeKey,
  ProductLandRightType,
  ProductLandRightTypeKey,
  ProductMonthSeasonKey,
  ProductPropertyCategoryKey,
  ProductReformKey,
  ProductSalesHouseStatus,
  ProductSalesHouseStatusKey,
  ProductSalesStatus,
  ProductSalesStatusKey,
  ProductSalesType,
  ProductSalesTypeKey,
  ProductStatus,
  ProductTransportMean,
  ProductTransportMeanKey,
  ProductPublication,
  SearchConditionAlertKey,
  CategoryNameKey,
  CategoryName,
  ProductImage,
  ProductPriceStatus,
  ProductPriceStatusKey,
  ProductVariantCreate,
  VariantCustomFields,
} from '@app/types/catalog';
import { Common, DateType, DateTypeKey } from '@app/types/common';

/**
 * 共通
 */
export type VariantEditFormKey = Omit<
  ProductVariantCreate,
  'price' | 'imageIds'
> & {
  id: string;
  imageId: string;
  url: string;
};

// メインビジュアル
export interface MainVisualFormKey {
  mainVisuals: ProductImage[];
}
export const MainVisualDefaultValues: MainVisualFormKey = {
  mainVisuals: [],
};
export const MainVisualForm = {
  defaultValues: MainVisualDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      mainVisuals: z
        .array(
          z.object({
            url: z.string(),
          })
        )
        .min(3, 'メインビジュアルを3つ以上選択してください'),
    })
  ),
} as const;

/**
 * Product edit form (Exhibition)
 */
export interface ExhibitionEditFormData {
  access: string;
  additionalInformation: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine3HiddenFlag: boolean;
  addressLine4: string;
  category: CategoryNameKey;
  cityId: string;
  closingTime: string[];
  contactAdditional: string;
  contactAddress: string;
  contactPhoneNumber: string[];
  description: string;
  features: { value: string }[];
  floorPlans: VariantEditFormKey[];
  mainVisuals: ProductImage[];
  name: string;
  openingTime: string[];
  postalCode: string;
  prefectureId: string;
  publication: ProductPublication;
  regularHoliday: string;
  regularHolidayAdditional: string;
  smaverse: string[];
}
export type ExhibitionEditFormDataKey = keyof ExhibitionEditFormData;
export const ExhibitionEditFormDefaultValues: ExhibitionEditFormData = {
  access: '',
  additionalInformation: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressLine3HiddenFlag: false,
  addressLine4: '',
  category: CategoryName.EXHIBITION,
  cityId: '',
  closingTime: ['', ''],
  contactAdditional: '',
  contactAddress: '',
  contactPhoneNumber: ['', '', ''],
  description: '',
  features: [{ value: '' }],
  floorPlans: [],
  mainVisuals: [],
  name: '',
  openingTime: ['', ''],
  postalCode: '',
  prefectureId: '',
  publication: {
    since: '',
    status: ProductStatus.ACTIVE,
    until: '',
  },
  regularHoliday: '',
  regularHolidayAdditional: '',
  smaverse: [],
};
export const ExhibitionEditForm = {
  defaultValues: ExhibitionEditFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      access: z.string().min(1, '主要交通を入力してください'),
      additionalInformation: z
        .string()
        .min(1, 'メインキャッチを入力してください'),
      addressLine1: z.string().min(1, '都道府県を選択してください'),
      addressLine2: z.string().min(1, '市区町村を選択してください'),
      addressLine3: z.string().min(1, '住所末尾を入力してください'),
      addressLine3HiddenFlag: z.boolean(),
      addressLine4: z.string(),
      category: z.string(),
      cityId: z.string(),
      closingTime: z.array(z.string().min(1, '営業終了時刻を選択してください')),
      contactAdditional: z.string(),
      contactAddress: z.string().min(1, '問い合わせ先を入力してください'),
      contactPhoneNumber: z.array(
        z
          .string()
          .min(1, '問い合わせ先電話番号を入力してください')
          .regex(/^[0-9]+$/, '半角数字で入力してください')
      ),
      description: z.string().min(1, 'サブキャッチを入力してください'),
      features: z.array(
        z.object({
          value: z.string().optional(),
        })
      ),
      floorPlans: z.array(
        z.object({
          description: z.string(),
          title: z.string(),
          url: z.string(),
        })
      ),
      mainVisuals: z
        .array(
          z.object({
            url: z.string(),
          })
        )
        .min(3, 'メインビジュアルを3つ以上選択してください'),
      name: z.string().min(1, '住宅展示場タイトルを入力してください'),
      openingTime: z.array(z.string().min(1, '営業開始時刻を選択してください')),
      postalCode: z.string().min(1, '郵便番号を入力してください'),
      prefectureId: z.string(),
      publication: z.object({
        since: z.string(),
        status: z.string(),
        until: z.string(),
      }),
      regularHoliday: z.string().min(1, '定休日を入力してください'),
      regularHolidayAdditional: z.string(),
      smaverse: z.array(z.string()),
    })
  ),
} as const;

/**
 * Product edit form (Property)
 */
export interface PropertyOverviewFormKey {
  additionalInformation: string;
  description: string;
  features: { value: string }[];
  name: string;
  previewAdComment: string;
  propertyCategory: ProductPropertyCategoryKey;
  salesStatus: ProductSalesStatusKey;
}
export interface PropertyBasicInfoFormKey {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine3HiddenFlag: boolean;
  addressLine4: string;
  autoStopReason: ProductAutoStopReasonKey;
  cityId: string;
  contactAdditional: string;
  contactAddress: string;
  contactPhoneNumber: string[];
  geoCode?: { lat: number; lng: number };
  lotteryDate: string;
  lotteryDateSeason: ProductMonthSeasonKey;
  lotteryDateType: DateTypeKey;
  postalCode: string;
  prefectureId: string;
  publication: ProductPublication;
  salesDateSeason: ProductMonthSeasonKey;
  salesDateType: DateTypeKey;
  salesEndDate: string;
  salesHouseCount: string; //建物情報の販売戸数を反映する
  salesScheduleComment: string;
  salesStartDate: string;
  salesStatus: ProductSalesStatusKey; //概要の販売状態を反映する
  salesType: ProductSalesTypeKey;
  transportMainLine: string;
  transportMainMean: ProductTransportMeanKey;
  transportMainNearest: string;
  transportMainNote: string;
  transportMainSeparateTimeMax: string[];
  transportMainSeparateTimeMin: string[];
  transportMainTimeMax: string;
  transportMainTimeMin: string;
  transportSub: {
    line: string;
    mean: ProductTransportMeanKey;
    nearest: string;
    note: string;
    separateTimeMax: string[];
    separateTimeMin: string[];
    timeMax: string;
    timeMin: string;
  }[];
  transportationFlag: boolean;
}
export interface PropertyPriceFormKey {
  facilityCost: string;
  maxPrice: string;
  minPrice: string;
  mostPriceRangeAdditional: string;
  mostPriceRangeCount: string;
  mostPriceRanges: string[];
  otherCost: string;
  priceAdditional: string;
  priceNote: string;
  priceRangeType: ProductRangeTypeKey;
  priceStatus: ProductPriceStatusKey;
  salesHouseCount: string; //建物情報の販売戸数を反映する
  salesStatus: ProductSalesStatusKey; //概要の販売状態を反映する
}
export interface PropertyLandInfoFormKey {
  areaRatio: string;
  buildingPermitReasonType: string;
  facility: string;
  landAreaRangeType: ProductRangeTypeKey;
  landAreaType: ProductAreaTypeKey;
  landCategory: string;
  landLeaseholdCost: string;
  landLeaseholdDuration: string;
  landLeaseholdRent: string;
  landRightType: ProductLandRightTypeKey;
  maxLandArea: string[];
  maxRoadWidth: string[];
  minLandArea: string[];
  minRoadWidth: string[];
  roadNote: string;
  roadPainting: string;
  roadWidthRangeType: ProductRangeTypeKey;
  tsuboNotationFlag: boolean;
  useDistrict: string;
  useDistricts: string[];
}
export interface PropertyBuildingInfoFormKey {
  buildingAreaRangeType: ProductRangeTypeKey;
  buildingAreaType: ProductAreaTypeKey;
  buildingConfirmationNumber: string;
  buildingStatus: ProductBuildingStatusKey;
  buildingSystem: string;
  completionDateMonth: string;
  completionDateType: ProductCompletionDateTypeKey;
  completionDateYear: string;
  completionSeason: ProductMonthSeasonKey;
  constructionCompany: string;
  deliveryDateMonth: string;
  deliveryDateType: ProductDeliveryDateTypeKey;
  deliveryDateYear: string;
  deliveryNote: string;
  deliverySeason: ProductMonthSeasonKey;
  floorPlanRangeType: ProductRangeTypeKey;
  floorPlanRoomsMax: string;
  floorPlanRoomsMin: string;
  floorPlanTypeAdditional: string;
  floorPlanTypeMax: string;
  floorPlanTypeMin: string;
  maxBuildingArea: string[];
  minBuildingArea: string[];
  reformExterior: ProductReformKey;
  reformInterior: ProductReformKey;
  salesHouseCount: string;
  salesHouseStatus: ProductSalesHouseStatusKey;
  totalHouseCount: string;
  transactionExpirationDate: string;
}
export interface PropertyCompanyInfoFormKey {
  companyAddress: string;
  companyName: string;
  conditionsTransaction: string;
  conditionsTransactionNote: string;
  groupName: string;
  licenseNumber: string;
  salesHouseCount: string; //建物情報の販売戸数を反映する
}
export interface PropertyLimitationFormKey {
  legalLimitations: string[];
  legalOtherLimitationNote: string;
  legalOtherLimitationType: string;
  legalOtherLimitations: string[];
}
export interface PropertyEventInfoFormKey {
  eventAction: string;
  eventContent: string;
  eventDateList: string[];
  eventDateSince: string;
  eventDateType: ProductEventDateTypeKey;
  eventDateUntil: string;
  eventName: ProductEventTitleKey;
  eventTimeSince: string[];
  eventTimeUntil: string[];
}
export interface PropertyAroundFormKey {
  arounds: VariantEditFormKey[];
}
export interface PropertyEquipmentFormKey {
  equipments: VariantEditFormKey[];
}
export interface PropertyExteriorFormKey {
  exteriors: VariantEditFormKey[];
}
export type PropertyFloorPlanEditFormKey = Omit<
  VariantEditFormKey,
  'customFields'
> & {
  customFields?: Omit<VariantCustomFields, 'buildingArea' | 'landArea'> & {
    buildingArea?: string[];
    landArea?: string[];
  };
};
export interface PropertyFloorPlanFormKey {
  floorPlans: PropertyFloorPlanEditFormKey[];
}
export interface PropertyInteriorFormKey {
  interiors: VariantEditFormKey[];
}
export interface PropertyPlotMapFormKey {
  plotMaps: VariantEditFormKey[];
}
export interface PropertyFormKey {
  around: PropertyAroundFormKey;
  basicInfo: PropertyBasicInfoFormKey;
  buildingInfo: PropertyBuildingInfoFormKey;
  companyInfo: PropertyCompanyInfoFormKey;
  equipment: PropertyEquipmentFormKey;
  eventInfo: PropertyEventInfoFormKey;
  exterior: PropertyExteriorFormKey;
  floorPlan: PropertyFloorPlanFormKey;
  interior: PropertyInteriorFormKey;
  landInfo: PropertyLandInfoFormKey;
  limitation: PropertyLimitationFormKey;
  overview: PropertyOverviewFormKey;
  plotMap: PropertyPlotMapFormKey;
  price: PropertyPriceFormKey;
}
export const PropertyFormDefaultValues = {
  around: { arounds: [] } as PropertyAroundFormKey,
  basicInfo: {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine3HiddenFlag: false,
    addressLine4: '',
    autoStopReason: Common.EMPTY,
    cityId: '',
    contactAdditional: '',
    contactAddress: '',
    contactPhoneNumber: ['', '', ''],
    geoCode: undefined,
    lotteryDate: '',
    lotteryDateSeason: Common.EMPTY,
    lotteryDateType: DateType.DAY,
    postalCode: '',
    prefectureId: '',
    publication: {
      since: '',
      status: ProductStatus.ACTIVE,
      until: '',
    },
    salesDateSeason: Common.EMPTY,
    salesDateType: DateType.DAY,
    salesEndDate: '',
    salesScheduleComment: '',
    salesStartDate: '',
    salesType: Common.EMPTY,
    transportMainLine: '',
    transportMainMean: Common.EMPTY,
    transportMainNearest: '',
    transportMainNote: '',
    transportMainSeparateTimeMax: ['', ''],
    transportMainSeparateTimeMin: ['', ''],
    transportMainTimeMax: '',
    transportMainTimeMin: '',
    transportationFlag: false,
  } as PropertyBasicInfoFormKey,
  buildingInfo: {
    buildingAreaRangeType: Common.EMPTY,
    buildingAreaType: '',
    buildingConfirmationNumber: '',
    buildingStatus: Common.EMPTY,
    buildingSystem: '',
    completionDateMonth: '',
    completionDateType: Common.EMPTY,
    completionDateYear: '',
    completionSeason: '',
    constructionCompany: '',
    deliveryDateMonth: '',
    deliveryDateType: Common.EMPTY,
    deliveryDateYear: '',
    deliveryNote: '',
    deliverySeason: '',
    floorPlanRangeType: Common.EMPTY,
    floorPlanRoomsMax: '',
    floorPlanRoomsMin: '',
    floorPlanTypeAdditional: '',
    floorPlanTypeMax: '',
    floorPlanTypeMin: '',
    maxBuildingArea: ['', ''],
    minBuildingArea: ['', ''],
    reformExterior: Common.EMPTY,
    reformInterior: Common.EMPTY,
    salesHouseCount: '',
    salesHouseStatus: Common.EMPTY,
    totalHouseCount: '',
    transactionExpirationDate: '',
  } as PropertyBuildingInfoFormKey,
  companyInfo: {
    companyAddress: '',
    companyName: '',
    conditionsTransaction: '',
    conditionsTransactionNote: '',
    groupName: '',
    licenseNumber: '',
  } as PropertyCompanyInfoFormKey,
  equipment: { equipments: [] } as PropertyEquipmentFormKey,
  eventInfo: {
    eventAction: '',
    eventContent: '',
    eventDateList: [],
    eventDateSince: '',
    eventDateType: Common.EMPTY,
    eventDateUntil: '',
    eventName: Common.EMPTY,
    eventTimeSince: ['', ''],
    eventTimeUntil: ['', ''],
  } as PropertyEventInfoFormKey,
  exterior: { exteriors: [] } as PropertyExteriorFormKey,
  floorPlan: { floorPlans: [] } as PropertyFloorPlanFormKey,
  interior: { interiors: [] } as PropertyInteriorFormKey,
  landInfo: {
    areaRatio: '',
    buildingPermitReasonType: '',
    facility: '',
    landAreaRangeType: Common.EMPTY,
    landAreaType: '',
    landCategory: '',
    landLeaseholdCost: '',
    landLeaseholdDuration: '',
    landLeaseholdRent: '',
    landRightType: '',
    maxLandArea: ['', ''],
    maxRoadWidth: ['', ''],
    minLandArea: ['', ''],
    minRoadWidth: ['', ''],
    roadNote: '',
    roadPainting: '',
    roadWidthRangeType: Common.EMPTY,
    tsuboNotationFlag: false,
    useDistrict: '',
    useDistricts: ['', '', ''],
  } as PropertyLandInfoFormKey,
  limitation: {
    legalLimitations: [],
    legalOtherLimitationNote: '',
    legalOtherLimitationType: '',
    legalOtherLimitations: [],
  } as PropertyLimitationFormKey,
  overview: {
    additionalInformation: '',
    description: '',
    features: [{ value: '' }],
    name: '',
    previewAdComment: '',
    propertyCategory: Common.EMPTY,
    salesStatus: Common.EMPTY,
  } as PropertyOverviewFormKey,
  plotMap: {
    plotMaps: [
      {
        customFields: { category: '' },
        description: '',
        id: '',
        imageId: '',
        title: '区画図',
        url: '',
      },
    ],
  } as PropertyPlotMapFormKey,
  price: {
    facilityCost: '',
    maxPrice: '',
    minPrice: '',
    mostPriceRangeAdditional: '',
    mostPriceRangeCount: '',
    mostPriceRanges: ['', '', '', ''],
    otherCost: '',
    priceAdditional: '',
    priceNote: '',
    priceRangeType: ProductRangeType.BETWEEN,
    priceStatus: Common.EMPTY,
  } as PropertyPriceFormKey,
};
export const PropertyOverviewForm = {
  defaultValues: PropertyFormDefaultValues.overview,
  mode: 'all',
  resolver: zodResolver(
    z
      .object({
        additionalInformation: requiredLengthBetween(
          'メインキャッチ',
          undefined,
          85
        ),
        description: requiredLengthBetween('サブキャッチ', undefined, 500),
        features: z.array(
          z.object({
            value: z.string().optional(),
          })
        ),
        name: required('物件名'),
        previewAdComment: z.string(),
        propertyCategory: required('種別', true),
        salesStatus: required('販売状態', true),
      })
      .refine(
        (data) => {
          return !(
            data.salesStatus === ProductSalesStatus.SCHEDULED_SALE &&
            !data.previewAdComment
          );
        },
        {
          message: '予告広告催促コメントを入力してください',
          path: ['previewAdComment'],
        }
      )
      .refine(
        (data) =>
          !(
            data.salesStatus === ProductSalesStatus.SOLD_OUT ||
            data.salesStatus === ProductSalesStatus.SELLING_STOP ||
            data.salesStatus === ProductSalesStatus.SECRET
          ),
        {
          message: '「完売・成約」「売り止め」「掲載止め」は掲載できません',
          path: ['salesStatus'],
        }
      )
  ),
} as const;
export const PropertyBasicInfoForm = {
  defaultValues: PropertyFormDefaultValues.basicInfo,
  mode: 'all',
  resolver: zodResolver(
    z
      .object({
        addressLine1: required('都道府県', true),
        addressLine2: required('市区町村', true),
        addressLine3: z.string(),
        addressLine3HiddenFlag: z.boolean(),
        addressLine4: optionalLengthBetween(undefined, 200),
        autoStopReason: z.string(),
        cityId: z.string(),
        contactAdditional: optionalLengthBetween(undefined, 200),
        contactAddress: requiredLengthBetween('問い合わせ先名', undefined, 35),
        contactPhoneNumber: z.array(requiredInt('問い合わせ先電話番号')),
        geoCode: z
          .object({
            lat: z.number().optional(),
            lng: z.number().optional(),
          })
          .optional(),
        lotteryDate: z.string(),
        lotteryDateSeason: z.string(),
        lotteryDateType: z.string(),
        postalCode: required('郵便番号'),
        prefectureId: z.string(),
        salesDateSeason: z.string(),
        salesDateType: z.string(),
        salesEndDate: z.string(),
        salesHouseCount: z.string(),
        salesScheduleComment: z.string(),
        salesStartDate: z.string(),
        salesStatus: z.string().optional(),
        salesType: z.string(),
        transportMainLine: z.string(),
        transportMainMean: z.string(),
        transportMainNearest: z.string(),
        transportMainNote: z.string(),
        transportMainSeparateTimeMax: z.array(optionalInt().optional()),
        transportMainSeparateTimeMin: z.array(optionalInt().optional()),
        transportMainTimeMax: z.string().optional(),
        transportMainTimeMin: z.string().optional(),
        transportSub: z.array(
          z
            .object({
              line: z.string(),
              mean: z.string(),
              nearest: z.string(),
              note: z.string(),
              separateTimeMax: z.array(optionalInt().optional()),
              separateTimeMin: z.array(optionalInt().optional()),
              timeMax: z.string().optional(),
              timeMin: z.string().optional(),
            })
            .refine(
              (data) => {
                return !(data.line === '');
              },
              {
                message: '路線(バス会社)を入力してください',
                path: ['line'],
              }
            )
            .refine(
              (data) => {
                return !(data.nearest === '');
              },
              {
                message: '駅(バス停)を入力してください',
                path: ['nearest'],
              }
            )
            .refine(
              (data) => {
                return !(data.mean === Common.EMPTY);
              },
              {
                message: '駅(バス停)までを入力してください',
                path: ['mean'],
              }
            )
            .refine(
              (data) => {
                return !(
                  data.mean === ProductTransportMean.WALK && !data.timeMin
                );
              },
              {
                message: '入力してください',
                path: ['timeMin'],
              }
            )
            .refine(
              (data) => {
                return !(
                  data.mean === ProductTransportMean.CAR &&
                  !data.separateTimeMin[0]
                );
              },
              {
                message: '入力してください',
                path: ['separateTimeMin.0'],
              }
            )
            .refine(
              (data) => {
                return !(
                  data.mean === ProductTransportMean.BUS && data.note === ''
                );
              },
              {
                message: '入力してください',
                path: ['note'],
              }
            )
        ),
        transportationFlag: z.boolean(),
      })
      .refine(
        (data) => {
          return !(
            data.salesType === ProductSalesType.LOTTERY && !data.lotteryDate
          );
        },
        {
          message: '抽選日を入力してください',
          path: ['lotteryDate'],
        }
      )
      .refine(
        (data) => {
          const salesHouseCount = Number(data.salesHouseCount);
          return !(
            data.addressLine3 === '' &&
            (salesHouseCount >= 10 || salesHouseCount === 1)
          );
        },
        {
          message: '住所末尾を入力してください',
          path: ['addressLine3'],
        }
      )
      .refine(
        (data) => {
          return !(!data.transportationFlag && data.transportMainLine === '');
        },
        {
          message: '路線(バス会社)を入力してください',
          path: ['transportMainLine'],
        }
      )
      .refine(
        (data) => {
          return !(
            !data.transportationFlag && data.transportMainNearest === ''
          );
        },
        {
          message: '駅(バス停)を入力してください',
          path: ['transportMainNearest'],
        }
      )
      .refine(
        (data) => {
          return !(
            !data.transportationFlag && data.transportMainMean === Common.EMPTY
          );
        },
        {
          message: '駅(バス停)までを入力してください',
          path: ['transportMainMean'],
        }
      )
      .refine(
        (data) => {
          return !(
            !data.transportationFlag &&
            data.transportMainMean === ProductTransportMean.WALK &&
            !data.transportMainTimeMin
          );
        },
        {
          message: '入力してください',
          path: ['transportMainTimeMin'],
        }
      )
      .refine(
        (data) => {
          return !(
            !data.transportationFlag &&
            data.transportMainMean === ProductTransportMean.CAR &&
            !data.transportMainSeparateTimeMin[0]
          );
        },
        {
          message: '入力してください',
          path: ['transportMainSeparateTimeMin.0'],
        }
      )
      .refine(
        (data) => {
          return !(
            !data.transportationFlag &&
            data.transportMainMean === ProductTransportMean.BUS &&
            data.transportMainNote === ''
          );
        },
        {
          message: '入力してください',
          path: ['transportMainNote'],
        }
      )
  ),
} as const;
export const PropertyPriceForm = {
  defaultValues: PropertyFormDefaultValues.price,
  mode: 'all',
  resolver: zodResolver(
    z
      .object({
        facilityCost: z.string(),
        maxPrice: optionalInt(),
        minPrice: optionalInt(),
        mostPriceRangeAdditional: z.string(),
        mostPriceRangeCount: optionalInt(),
        mostPriceRanges: z.array(optionalInt()),
        otherCost: z.string(),
        priceAdditional: z.string(),
        priceNote: z.string(),
        priceRangeType: z.string(),
        priceStatus: required('価格', true),
        salesHouseCount: z.string(),
        salesStatus: z.string(),
      })
      .refine(
        (data) =>
          data.priceStatus === ProductPriceStatus.PENDING ||
          data.minPrice !== '',
        {
          message: '入力してください',
          path: ['minPrice'],
        }
      )
      .refine(
        (data) => {
          return !(
            !data.mostPriceRanges.some((v) => v !== '') &&
            (data.salesStatus === ProductSalesStatus.NEW_BUILDING_CONDO ||
              data.salesStatus === ProductSalesStatus.USED_CONDO) &&
            Number(data.salesHouseCount) >= 10
          );
        },
        {
          message: '入力してください',
          path: ['mostPriceRanges.0'],
        }
      )
  ),
} as const;
export const PropertyLandInfoForm = {
  defaultValues: PropertyFormDefaultValues.landInfo,
  mode: 'all',
  resolver: zodResolver(
    z
      .object({
        areaRatio: z.string(),
        buildingPermitReasonType: z.string(),
        facility: z.string(),
        landAreaRangeType: z.string(),
        landAreaType: z.string(),
        landCategory: z.string(),
        landLeaseholdCost: z.string(),
        landLeaseholdDuration: z.string(),
        landLeaseholdRent: z.string(),
        landRightType: required('土地の権利形態'),
        maxLandArea: z.array(optionalInt()),
        maxRoadWidth: z.array(optionalInt()),
        minLandArea: z.array(optionalInt()),
        minRoadWidth: z.array(optionalInt()),
        roadNote: z.string(),
        roadPainting: z.string(),
        roadWidthRangeType: z.string(),
        tsuboNotationFlag: z.boolean(),
        useDistrict: required('用途地域', true),
        useDistricts: z.array(z.string().optional()),
      })
      .refine((data) => data.minLandArea[0] !== '', {
        message: '土地面積を入力してください',
        path: ['minLandArea.0'],
      })
      .refine(
        (data) =>
          data.landRightType === ProductLandRightType.OWNERSHIP ||
          data.landLeaseholdDuration !== '',
        {
          message: '借地権の場合、その期間等を入力してください',
          path: ['landLeaseholdDuration'],
        }
      )
      .refine(
        (data) =>
          data.landRightType === ProductLandRightType.OWNERSHIP ||
          data.landLeaseholdRent !== '',
        {
          message: '借地権の場合その月賃料を入力してください',
          path: ['landLeaseholdRent'],
        }
      )
      .refine(
        (data) =>
          !(
            data.useDistrict === '市街化調整区域' ||
            data.useDistricts.includes('市街化調整区域')
          ) || data.buildingPermitReasonType !== '',
        {
          message: '建築許可理由を入力してください',
          path: ['buildingPermitReasonType'],
        }
      )
  ),
} as const;
export const PropertyBuildingInfoForm = {
  defaultValues: PropertyFormDefaultValues.buildingInfo,
  mode: 'all',
  resolver: zodResolver(
    z
      .object({
        buildingAreaRangeType: z.string(),
        buildingAreaType: z.string(),
        buildingConfirmationNumber: z.string(),
        buildingStatus: required('建物状況'),
        buildingSystem: z.string(),
        completionDateMonth: requiredIntBetween('完成時期', 1, 12),
        completionDateType: required('完成時期', true),
        completionDateYear: optionalIntLength(4),
        completionSeason: z.string(),
        constructionCompany: z.string(),
        deliveryDateMonth: optionalIntBetween(1, 12),
        deliveryDateType: required('引渡可能時期', true),
        deliveryDateYear: optionalIntLength(4),
        deliveryNote: z.string(),
        deliverySeason: z.string(),
        floorPlanRangeType: z.string(),
        floorPlanRoomsMax: optionalIntBetween(1, 30),
        floorPlanRoomsMin: requiredIntBetween('間取りタイプ', 1, 30),
        floorPlanTypeAdditional: z.string(),
        floorPlanTypeMax: z.string(),
        floorPlanTypeMin: required('間取りタイプ'),
        maxBuildingArea: z.array(optionalInt()),
        minBuildingArea: z.array(optionalInt()),
        reformExterior: z.string(),
        reformInterior: z.string(),
        salesHouseCount: optionalInt(),
        salesHouseStatus: required('販売戸数', true),
        totalHouseCount: required('総戸数', false),
        transactionExpirationDate: required('取引有効期限'),
      })
      .refine((data) => data.minBuildingArea[0] !== '', {
        message: '建物面積を入力してください',
        path: ['minBuildingArea.0'],
      })
      .refine(
        (data) =>
          data.completionDateType === ProductCompletionDateType.CONTRACTED ||
          data.completionDateYear,
        {
          message: '完成時期を入力してください',
          path: ['completionDateYear'],
        }
      )
      .refine(
        (data) =>
          data.deliveryDateType === ProductDeliveryDateType.CONSULTATION ||
          data.deliveryDateType === ProductDeliveryDateType.IMMEDIATE ||
          data.deliveryDateType === ProductDeliveryDateType.CONTRACTED ||
          data.deliveryDateYear,
        {
          message: '引渡可能時期を入力してください',
          path: ['deliveryDateYear'],
        }
      )
      .refine(
        (data) =>
          data.deliveryDateType === ProductDeliveryDateType.CONSULTATION ||
          data.deliveryDateType === ProductDeliveryDateType.IMMEDIATE ||
          data.deliveryDateMonth,
        {
          message: '引渡可能時期を入力してください',
          path: ['deliveryDateMonth'],
        }
      )
      .refine(
        (data) =>
          data.salesHouseStatus === ProductSalesHouseStatus.PENDING ||
          data.salesHouseCount !== '',
        {
          message: '販売戸数を入力してください',
          path: ['salesHouseCount'],
        }
      )
      .refine(
        (data) =>
          data.transactionExpirationDate !== '' &&
          isBefore(
            subDays(startOfDay(new Date()), 1),
            new Date(data.transactionExpirationDate)
          ),
        {
          message: '取引有効期限を過ぎています',
          path: ['transactionExpirationDate'],
        }
      )
      .refine((data) => !(!!data.floorPlanTypeMax && !data.floorPlanRoomsMax), {
        message: '間取りを入力してください',
        path: ['floorPlanRoomsMax'],
      })
      .refine((data) => !(!!data.floorPlanRoomsMax && !data.floorPlanTypeMax), {
        message: '間取りを入力してください',
        path: ['floorPlanTypeMax'],
      })
      .refine(
        (data) =>
          !(
            (!!data.floorPlanRoomsMax || !!data.floorPlanTypeMax) &&
            !data.floorPlanRangeType
          ),
        {
          message: '間取りを入力してください',
          path: ['floorPlanRangeType'],
        }
      )
  ),
} as const;
export const PropertyCompanyInfoForm = {
  defaultValues: PropertyFormDefaultValues.companyInfo,
  mode: 'all',
  resolver: zodResolver(
    z
      .object({
        companyAddress: z.string().min(1, '住所を入力してください'),
        companyName: z.string().min(1, '社名を入力してください'),
        conditionsTransaction: z.string().min(1, '取引態様を選択してください'),
        conditionsTransactionNote: z.string(),
        groupName: z.string(),
        licenseNumber: z.string(),
        salesHouseCount: z.string(),
      })
      .refine(
        (data) => {
          return Number(data.salesHouseCount) < 10 || data.licenseNumber;
        },
        {
          message: '免許番号を入力してください',
          path: ['licenseNumber'],
        }
      )
  ),
} as const;
export const PropertyLimitationForm = {
  defaultValues: PropertyFormDefaultValues.limitation,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      legalLimitations: z.array(z.string().optional()),
      legalOtherLimitationNote: z.string(),
      legalOtherLimitationType: z.string(),
      legalOtherLimitations: z.array(z.string().optional()),
    })
  ),
} as const;
export const PropertyEventInfoForm = {
  defaultValues: PropertyFormDefaultValues.eventInfo,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      eventAction: z.string(),
      eventContent: z.string(),
      eventDateList: z.array(z.string().optional()),
      eventDateSince: z.string(),
      eventDateType: z.string(),
      eventDateUntil: z.string(),
      eventName: z.string(),
      eventTimeSince: z.array(z.string().optional()),
      eventTimeUntil: z.array(z.string().optional()),
    })
  ),
} as const;
export const PropertyAroundForm = {
  defaultValues: PropertyFormDefaultValues.around,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      arounds: z
        .array(
          z.object({
            description: z.string().max(130, '130文字以内で入力してください'),
            url: z.string(),
          })
        )
        .max(10),
    })
  ),
} as const;
export const PropertyEquipmentsForm = {
  defaultValues: PropertyFormDefaultValues.equipment,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      equipments: z
        .array(
          z.object({
            description: z.string().max(200, '200文字以内で入力してください'),
            title: z.string().max(35, '35文字以内で入力してください'),
            url: z.string(),
          })
        )
        .max(15),
    })
  ),
} as const;
export const PropertyExteriorForm = {
  defaultValues: PropertyFormDefaultValues.exterior,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      exteriors: z
        .array(
          z.object({
            description: z.string().max(130, '130文字以内で入力してください'),
            url: z.string(),
          })
        )
        .max(20),
    })
  ),
} as const;
export const PropertyFloorPlanForm = {
  defaultValues: PropertyFormDefaultValues.floorPlan,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      floorPlans: z
        .array(
          z.object({
            description: z.string().max(100, '100文字以内で入力してください'),
            title: z.string().max(35, '35文字以内で入力してください'),
            url: z.string(),
          })
        )
        .max(10),
    })
  ),
} as const;
export const PropertyInteriorForm = {
  defaultValues: PropertyFormDefaultValues.interior,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      interiors: z
        .array(
          z.object({
            description: z.string().max(130, '130文字以内で入力してください'),
            url: z.string(),
          })
        )
        .max(20),
    })
  ),
} as const;
export const PropertyPlotMapForm = {
  defaultValues: PropertyFormDefaultValues.plotMap,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      plotMaps: z
        .array(
          z.object({
            description: z.string().max(100, '100文字以内で入力してください'),
            url: z.string(),
          })
        )
        .max(1),
    })
  ),
} as const;

/**
 * Product search form (Exhibition)
 */
export interface ExhibitionSearchFormData {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  alert: SearchConditionAlertKey;
  id: string;
  name: string;
  postalCode: string;
  sort: string;
}
export type ExhibitionSearchFormDataKey = keyof ExhibitionSearchFormData;
export const ExhibitionSearchFormDefaultValues: ExhibitionSearchFormData = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressLine4: '',
  alert: '',
  id: '',
  name: '',
  postalCode: '',
  sort: 'createdAt desc',
};
export const ExhibitionSearchFormSchema = z.object({
  addressLine1: z.string().optional(),
  addressLine2: z.string().optional(),
  addressLine3: z.string().optional(),
  addressLine4: z.string().optional(),
  alert: z.string().optional(),
  id: z.string().optional(),
  name: z.string().optional(),
  postalCode: z.string().optional(),
});
export const ExhibitionSearchForm = {
  defaultValues: ExhibitionSearchFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(ExhibitionSearchFormSchema),
} as const;

/**
 * Product search form (Property)
 */
export interface PropertySearchFormData {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  alert: SearchConditionAlertKey;
  categories: ProductPropertyCategoryKey[];
  id: string;
  isSalesStatusByStop: boolean;
  name: string;
  postalCode: string;
  publicationEnd: string;
  sort: string;
  transactionExpirationEnd: string;
}
export type PropertySearchFormDataKey = keyof PropertySearchFormData;
export const PropertySearchFormDefaultValues: PropertySearchFormData = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressLine4: '',
  alert: '',
  categories: [],
  id: '',
  isSalesStatusByStop: false,
  name: '',
  postalCode: '',
  publicationEnd: '',
  sort: 'createdAt desc',
  transactionExpirationEnd: '',
};
export const PropertySearchForm = {
  defaultValues: PropertySearchFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      addressLine1: z.string(),
      addressLine2: z.string(),
      addressLine3: z.string(),
      addressLine4: z.string(),
      alert: z.string(),
      categories: z.array(z.string().optional()),
      id: z.string(),
      isSalesStatusByStop: z.boolean(),
      name: z.string(),
      postalCode: z.string(),
      publicationEnd: z.string(),
      transactionExpirationEnd: z.string(),
    })
  ),
} as const;

/**
 * Product search form for admin
 */
export interface ProductSearchFormAdminData {
  organizationIds: string[];
  sort: string;
  status: string;
  yearMonth: string;
}
export const ProductSearchFormAdminDefaultValues: ProductSearchFormAdminData = {
  organizationIds: [],
  sort: 'createdAt desc',
  status: '',
  yearMonth: `${format(new Date(), 'yyyy/MM')}/01`,
};
export const ProductSearchFormAdminSchema = z.object({
  organizationIds: z.array(z.string().optional()),
  sort: z.string().optional(),
  status: z.string().optional(),
  yearMonth: z.string().optional(),
});
export const ProductSearchFormAdmin = {
  defaultValues: ProductSearchFormAdminDefaultValues,
  mode: 'all',
  resolver: zodResolver(ProductSearchFormAdminSchema),
} as const;
