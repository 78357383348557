import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { NumericTextField } from '@app/components/Shared/Inputs/NumericTextField';

export interface DecimalPointSeparateProps {
  decimalPrecision?: number; //有効な小数点以下の桁数
  endAdornment?: ReactElement | string;
  field1stName: string;
  field2ndName: string;
  hideErrorMessage?: boolean;
}

export function DecimalPointSeparate({
  decimalPrecision = 3, //NOTE: 不動産登記法で必要とされる面積は小数第2位まで。バッファ込で3桁に設定。（DecimalPointSeparateが主に面積入力で使用されているため）
  endAdornment,
  field1stName,
  field2ndName,
  hideErrorMessage = false,
}: DecimalPointSeparateProps): ReactElement {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Stack direction="row" spacing={0.5} alignItems="end">
        <Controller
          name={field1stName}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericTextField {...field} error={!!error} sx={{ width: 80 }} />
          )}
        />
        <Typography>.</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Controller
            name={field2ndName}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumericTextField
                {...field}
                error={!!error}
                sx={{ width: 80 }}
                maxLength={decimalPrecision}
              />
            )}
          />
          {!!endAdornment && <Typography>{endAdornment}</Typography>}
        </Stack>
      </Stack>
      {!hideErrorMessage &&
        Array.from(
          new Set([
            get(errors, `${field1stName}.message`, ''),
            get(errors, `${field2ndName}.message`, ''),
          ])
        )
          .filter((m) => !!m)
          .map((message, index) => (
            <FormHelperText key={index} error>
              {message as string}
            </FormHelperText>
          ))}
    </Box>
  );
}
